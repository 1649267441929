import { Box, Card, CardHeader, Stack, Typography } from "@mui/material";
import axios from "axios";
import RteTmChart from "components/charts/tsoCommunication/rte/RteTmChart";
import RteTsChart from "components/charts/tsoCommunication/rte/RteTsChart";
import CustomExportButton from "components/commons/buttons/CustomExportButton";
import { baseResources } from "config_infos";
import { addDays } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTimeInput, Form, required, ResourceContextProvider, useRecordContext } from "react-admin";
import { RteConfigType } from "types";
import { Ti2Enum, Ti2TmEnum, Ti2TsEnum } from "types/enums";

const RteCommunicationGraphVisualizations = () => {
  const group = useRecordContext();
  const [configs, setConfigs] = useState<RteConfigType[]>([]);
  const canFetch = !!group && group.type === "rte_edr";

  const getConfigs = useCallback(async () => {
    const {
      data: { items },
    } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_IEC_API_URL}/${baseResources.iec.CONFIGS}`,
      params: {
        page: 1,
        size: 100,
        code: group?.code_tm || "",
      },
    });
    return items;
  }, [group]);

  useEffect(() => {
    if (canFetch) {
      getConfigs()
        .then((configs) => {
          setConfigs(configs.filter((config: RteConfigType) => config.ti_2 !== Ti2Enum.N_RSFP));
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [canFetch, getConfigs]);

  if (!canFetch || configs.length === 0)
    return (
      <Typography variant="h6" align="center">
        No data to display
      </Typography>
    );

  const [tmIoas, tsIoas] = configs.reduce(
    (acc: [number[], { [key: string]: number }], config: RteConfigType) => {
      if (Object.values(Ti2TmEnum).includes(config.ti_2 as Ti2TmEnum)) {
        acc[0].push(config.ioa);
      }
      if (Object.values(Ti2TsEnum).includes(config.ti_2 as Ti2TsEnum)) {
        acc[1][config.ti_2] = config.ioa;
      }
      return acc;
    },
    [[], {}],
  );

  return (
    <Card sx={{ p: 4 }}>
      <CardHeader title="RTE Communication Graph Visualizations" />
      <RteTmChart ioas={tmIoas} />
      <RteTsChart ioas={tsIoas} />
      <ExportPackets tsIoas={tsIoas} />
    </Card>
  );
};

export default RteCommunicationGraphVisualizations;

type ExportPacketsProps = {
  tsIoas: { [key: string]: number };
};

const ExportPackets = ({ tsIoas }: ExportPacketsProps) => {
  const [range, setRange] = useState({ start_date: "", end_date: "" });
  const [disabled, setDisabled] = useState(true);

  const validate = (values: any) => {
    const { start_date, end_date } = values;
    const errors: { [key: string]: string } = {};
    if (!start_date) {
      errors.start_date = "Start date is required.";
    }
    if (!end_date) {
      errors.end_date = "End date is required.";
    }

    if (start_date && end_date) {
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);
      const maxEndDate = addDays(startDate, 30);

      if (endDate <= startDate) {
        errors.end_date = "End date must be after start date.";
      } else if (end_date > maxEndDate) {
        errors.end_date = "End date can be maximum 30 days after start date.";
      }
    }
    if (Object.keys(errors).length === 0) {
      setDisabled(false);
    }
    return errors;
  };

  const filter = useMemo(() => {
    return {
      timestamp__gte: range.start_date,
      timestamp__lte: range.end_date,
      ioa__in: Object.values(tsIoas),
    };
  }, [range, tsIoas]);

  return (
    <ResourceContextProvider value={baseResources.iec.PACKETS}>
      <Form sanitizeEmptyValues mode="onBlur" validate={validate}>
        <Typography variant="h6" sx={{ m: 2 }}>
          Export TS
        </Typography>
        <Stack direction="row" spacing={2} alignItems={"center"}>
          <DateTimeInput
            source="start_date"
            onChange={(e) => setRange((prev) => ({ ...prev, start_date: e.target.value }))}
            validate={[required()]}
            sx={{ minWidth: 300 }}
          />
          <DateTimeInput
            source="end_date"
            onChange={(e) => setRange((prev) => ({ ...prev, end_date: e.target.value }))}
            validate={[required()]}
            sx={{ minWidth: 300 }}
          />
          <Box pb={2}>
            <CustomExportButton filename="rte_communication_packets" filter={filter} disabled={disabled} />
          </Box>
        </Stack>
      </Form>
    </ResourceContextProvider>
  );
};
