import { enumToChoices } from "helpers/reactAdminHelpers";
import {
  SiteContryEnum,
  SiteFinlandDsoEnum,
  SiteFrenchDsoEnum,
  SiteOperatorEnum,
  SiteFranceSupplierEnum,
  SiteFinlandSupplierEnum,
  RteComputationVersionEnum,
  ChronicleFilenamesEnum,
  PlanningMaskModeEnum,
  FranceGroupTypeEnum,
  GroupModeEnum,
  allDsoEnum,
  GroupTypeEnum,
  PlanningModeEnum,
  FrancePlanningModeEnum,
  FinlandPlanningModeEnum,
  MaintenanceTypeEnum,
  SiteModeEnum,
  FinlandGroupTypeEnum,
  DirectionEnum,
  AfrrPointStatusEnum,
  TopNiveauGateStatusEnum,
} from "./globalEnums";

export type RaChoiceType = {
  id: string;
  name: string;
};

export const siteFrenchDsoChoices = enumToChoices(SiteFrenchDsoEnum);
export const siteFinlandDsoChoices = enumToChoices(SiteFinlandDsoEnum);
export const allDsoChoices = enumToChoices(allDsoEnum);

export const siteCountryChoices = enumToChoices(SiteContryEnum);

export const siteOperatorChoices = enumToChoices(SiteOperatorEnum);

export const siteFranceSupplierChoices = enumToChoices(SiteFranceSupplierEnum);
export const siteFinlandSupplierChoices = enumToChoices(SiteFinlandSupplierEnum);

export const rteComputationVersionChoices = enumToChoices(RteComputationVersionEnum);

export const chronicleFilenamesChoices = enumToChoices(ChronicleFilenamesEnum);

export const planningModeChoices = enumToChoices(PlanningModeEnum);
export const francePlanningModeChoices = enumToChoices(FrancePlanningModeEnum);
export const finlandPlanningModeChoices = enumToChoices(FinlandPlanningModeEnum);
export const planningMaskModeChoices = enumToChoices(PlanningMaskModeEnum);

export const groupModeChoices = enumToChoices(GroupModeEnum);
export const groupTypeChoices = enumToChoices(GroupTypeEnum);
export const franceGroupTypeChoices = enumToChoices(FranceGroupTypeEnum);
export const finlandGroupTypeChoices = enumToChoices(FinlandGroupTypeEnum);

export const maintenanceTypeChoices = enumToChoices(MaintenanceTypeEnum);

export const siteModeChoices = enumToChoices(SiteModeEnum);

export const directionChoices = enumToChoices(DirectionEnum);
export const afrrPointStatusChoices = enumToChoices(AfrrPointStatusEnum);

export const topNiveauGateStatusChoices = enumToChoices(TopNiveauGateStatusEnum);
