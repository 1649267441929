import { Box } from "@mui/material";
import { Labeled, TextField } from "react-admin";

type InputContainerProps = {
  source: string;
  Field?: React.ComponentType<any>;
  labelCustomProps?: any;
  fieldCustomProps?: any;
};

const InputContainer = ({ source, labelCustomProps, fieldCustomProps, Field = TextField }: InputContainerProps) => (
  <Box flex={1} pl={2} pr={2}>
    <Labeled source={source} {...labelCustomProps}>
      <Field source={source} fullWidth {...fieldCustomProps} />
    </Labeled>
  </Box>
);

export default InputContainer;
