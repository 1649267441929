import { useTheme } from "@mui/material";
import { baseResources } from "config_infos";
import { addDays, subDays, startOfWeek, endOfWeek } from "date-fns";
import { MaintenanceTypeEnum } from "enums/globalEnums";
import useFetchPaginatedResource from "hooks/useFetchPaginatedResource";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SortPayload, useDataProvider } from "react-admin";
import { PaginationType } from "types";
import { MaintenanceEvent, MaintenanceType } from "../types";
import { enumToValueArray } from "helpers/reactAdminHelpers";

const useMaintenanceCalendar = () => {
  const muiTheme = useTheme();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sites, setsites] = useState<{ [key: string]: any }[]>([]);
  const dataprovider = useDataProvider();

  const fetchMaintenances = useCallback(
    async ({ page, size }: PaginationType) => {
      const start = subDays(startOfWeek(selectedDate), 1);
      const end = addDays(endOfWeek(selectedDate), 1);
      const config = {
        sort: { field: "id", order: "ASC" } as SortPayload,
        pagination: { page, perPage: size },
        filter: {
          end__gte: start.toISOString(),
          start__lte: end.toISOString(),
        },
      };

      const { data: items, total } = await dataprovider.getList(baseResources.maintenance.MAINTENANCE, config);
      return { items, total: total as number };
    },
    [selectedDate, dataprovider],
  );

  // Use the useFetchPaginatedResource hook to handle data fetching
  const { loading, items: maintenances } = useFetchPaginatedResource(fetchMaintenances, 100, [selectedDate]);

  const fetchSites = useCallback(async () => {
    const allSiteIds: number[] = maintenances.map((maintenance: MaintenanceType) => maintenance.site_id);
    const uniqueSiteIds = [...new Set(allSiteIds)];
    const { data } = await dataprovider.getList(baseResources.sites.SITES, {
      sort: { field: "id", order: "ASC" } as SortPayload,
      pagination: { page: 1, perPage: 100 },
      filter: {
        id__in: uniqueSiteIds,
      },
    });
    return data;
  }, [dataprovider, maintenances]);

  // Mapping the maintenance data to calendar events
  const events = useMemo(() => {
    return maintenances.map(({ id, type, operator, start, end, site_id, ...rest }: MaintenanceType) => {
      const targetedSite = sites.find((site: { [key: string]: any }) => site.id === site_id);
      const title = targetedSite ? targetedSite.name : `Site ${site_id}`;
      return {
        id,
        title,
        start: new Date(start),
        end: new Date(end),
        allDay: true,
        type,
        operator,
        ...rest,
      };
    });
  }, [maintenances, sites]);

  const getMaintenanceColor = useCallback(
    (type: MaintenanceTypeEnum) => {
      switch (type) {
        case MaintenanceTypeEnum.RE:
          return "#95db1d";
        case MaintenanceTypeEnum.Enedis:
          return muiTheme.palette.info.main;
        case MaintenanceTypeEnum.Fortuit:
          return muiTheme.palette.error.main;
        case MaintenanceTypeEnum.Preventive:
          return muiTheme.palette.warning.main;
        default:
          return "gainsboro";
      }
    },
    [muiTheme.palette],
  );

  const chipColorInfos = useMemo(
    () =>
      enumToValueArray(MaintenanceTypeEnum).map((type) => ({
        label: type,
        color: getMaintenanceColor(type),
      })),
    [getMaintenanceColor],
  );

  // Event color styling based on maintenance type
  const eventPropGetter = (event: MaintenanceEvent) => {
    const backgroundColor = getMaintenanceColor(event.type);

    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "5px",
        border: "none",
      },
    };
  };

  // Handle navigation actions (back, next, today)
  const handleNavigate = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if (maintenances.length === 0) return;
    fetchSites().then((data) => setsites(data));
  }, [fetchSites, maintenances]);

  return {
    loading,
    events,
    selectedDate,
    chipColorInfos,
    eventPropGetter,
    handleNavigate,
  };
};

export default useMaintenanceCalendar;
