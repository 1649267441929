import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import CustomExportButton from "components/commons/buttons/CustomExportButton";
import { DateFieldFr } from "components/commons/fields/DefaultFields";
import { baseResources } from "config_infos";
import { useMemo, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateTimeInput,
  FilterButton,
  List,
  ListControllerProps,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useStore,
} from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import MaintenanceCalendar from "./calendar/MaintenanceCalendar";

enum ViewEnum {
  calendar = "calendar",
  list = "list",
}

export const MaintenancesList = (props: ListControllerProps) => {
  const { pathname } = useLocation();
  const isSiteShow = pathname.includes("show");
  const navigate = useNavigate();
  const record = useRecordContext();
  // eslint-disable-next-line
  const [_, setSiteShowId] = useStore(`${baseResources.maintenance.MAINTENANCE}.list.siteShowId`);
  const [view, setView] = useState<ViewEnum>(ViewEnum.list);

  const handleClick = () => {
    setSiteShowId(record?.id || "");
    navigate(`/${baseResources.maintenance.MAINTENANCE}/create`);
  };

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: ViewEnum) => {
    setView(newView);
  };

  const customFilters = useMemo(
    () => [
      <SearchInput key={"search"} source="search" alwaysOn autoFocus />,
      <TextInput key="type" label="Type" source="type" />,
      <DateTimeInput key="start__gt" label="Start date greater than" source="start__gt" />,
      <DateTimeInput key="start__lt" label="Start date lower than" source="start__lt" />,
      <DateTimeInput key="end__gt" label="End Date greater than" source="end__gt" />,
      <DateTimeInput key="end__lt" label="End Date lower than" source="end__lt" />,
      ...(!isSiteShow
        ? [<ReferenceInput key={"site_id"} label="Site" source="site_id" reference={baseResources.sites.SITES} />]
        : []),
    ],
    [isSiteShow],
  );

  return (
    <Stack direction={"column"} spacing={2}>
      {!isSiteShow && (
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          onChange={handleViewChange}
          sx={{
            justifyContent: "center",
            pt: 2,
          }}
        >
          <ToggleButton value={ViewEnum.list}>{ViewEnum.list}</ToggleButton>
          <ToggleButton value={ViewEnum.calendar}>{ViewEnum.calendar}</ToggleButton>
        </ToggleButtonGroup>
      )}

      {view === ViewEnum.list && (
        <List
          {...props}
          title={isSiteShow ? " " : "Maintenance"}
          filters={customFilters}
          filterDefaultValues={{ end__gt: new Date().toISOString() }}
          actions={
            <TopToolbar>
              <FilterButton />
              <CreateButton onClick={handleClick} />
              {!isSiteShow && <CustomExportButton includeDeleted filename="maintenances" />}
            </TopToolbar>
          }
          empty={
            <Box textAlign={"center"} margin={"auto"}>
              <Typography variant="h6" fontStyle={"italic"} gutterBottom color={"grey"}>
                Pas de maintenance en cours
              </Typography>
              <CreateButton onClick={handleClick} />
            </Box>
          }
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <ReferenceField source="site_id" sortable={false} reference={baseResources.sites.SITES} link="show" />
            <DateFieldFr source="start" sortable={true} showTime />
            <DateFieldFr source="end" sortable={true} showTime />
            <TextField source="type" sortable={false} />
            <TextField source="comment" sortable={false} />
            <ReferenceField
              source="site_id"
              sortable={false}
              reference={baseResources.sites.SITES}
              link={false}
              label={"Groups"}
            >
              <ReferenceArrayField
                source="group_ids"
                reference={baseResources.sites.GROUPS}
                filter={{ type: "rte_edr" }}
              />
            </ReferenceField>
            <DateFieldFr source="updated_at" sortable={false} showTime />
            <DateFieldFr source="created_at" sortable={false} showTime />
            <TextField source="operator" sortable={false} />
          </Datagrid>
        </List>
      )}

      {view === ViewEnum.calendar && (
        <Box p={2}>
          <MaintenanceCalendar />
        </Box>
      )}
    </Stack>
  );
};
