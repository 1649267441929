import { Box, Chip, Stack } from "@mui/material";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import { fr } from "date-fns/locale";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Loading } from "react-admin";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import useMaintenanceCalendar from "./hooks/useMaintenanceCalendar";
import MaintenanceCalendarTooltip from "./MaintenanceCalendarTooltip";

const locales = {
  fr: fr,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const MaintenanceCalendar = () => {
  const { loading, events, selectedDate, chipColorInfos, eventPropGetter, handleNavigate } = useMaintenanceCalendar();

  if (loading) {
    return <Loading />;
  }

  return (
    <Box height={"calc(100vh - 200px)"}>
      <Stack direction="row" spacing={1} pb={2}>
        {chipColorInfos.map(({ label, color }) => (
          <Chip
            label={label}
            key={label}
            sx={{
              backgroundColor: color,
              color: "white",
              width: 100,
            }}
          />
        ))}
      </Stack>
      <Calendar
        localizer={localizer}
        events={events}
        date={selectedDate} // Control the calendar view with selectedDate
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        eventPropGetter={eventPropGetter}
        style={{ height: "100%" }}
        views={[Views.WEEK]} // Only show Week view
        onNavigate={handleNavigate}
        components={{
          event: ({ event }) => <div title={""}>{event.title}</div>, // Remove default title tooltip
          eventWrapper: ({ event, children }: any) => (
            <MaintenanceCalendarTooltip event={event}>{children}</MaintenanceCalendarTooltip>
          ),
        }}
        allDayAccessor={() => true} // Treat all events as all-day events
      />
    </Box>
  );
};

export default MaintenanceCalendar;
