import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";

type CustomDialogProps = {
  onClose: () => void;
  open: boolean;
  title: string;
  children: React.ReactNode;
  actionComponentContent?: React.ReactNode;
  scroll?: "body" | "paper";
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  onCloseButton?: boolean;
};

export default function CustomDialog({
  onClose,
  open,
  title,
  scroll = "paper",
  maxWidth = "lg",
  onCloseButton = true,
  children,
  actionComponentContent,
}: CustomDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog onClose={onClose} open={open} scroll={scroll} fullScreen={fullScreen} maxWidth={maxWidth}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {actionComponentContent
          ? actionComponentContent
          : onCloseButton && (
              <Button onClick={onClose} variant="contained">
                Cancel
              </Button>
            )}
      </DialogActions>
    </Dialog>
  );
}
