import StatusAware from "components/commons/statuses/StatusAware";
import { PlanningsBarChart } from "./PlanningsBarChart";
import { usePlanningsData } from "./hooks/usePlanningsData";
import DateRangePicker from "components/commons/pickers/DateRangePicker";

export default function PlanningChart({ siteId }: any) {
  const { data, error, loading, setDateRange } = usePlanningsData(siteId);

  return (
    <StatusAware error={error} loading={loading}>
      <DateRangePicker setDateRange={setDateRange} dateRange={data.dateRange} />
      <PlanningsBarChart {...data} />
    </StatusAware>
  );
}
