import { Box, Stack, Typography, useTheme } from "@mui/material";
import InputContainer from "components/commons/inputs/InputContainer";
import ColorTag from "components/commons/tags/ColorTag";
import { baseResources } from "config_infos";
import { allDsoChoices, siteCountryChoices, siteModeChoices, siteOperatorChoices } from "enums/reactAdminChoices";
import { useCallback, useMemo } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Datagrid,
  DateTimeInput,
  EditButton,
  List,
  NumberInput,
  RaRecord,
  ReferenceArrayField,
  ReferenceInput,
  SearchInput,
  SelectField,
  ShowButton,
  SingleFieldList,
  SortPayload,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";

const SiteRowExpand = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Box display={{ xs: "block", sm: "flex" }}>
      <InputContainer source="zipcode" />
      <InputContainer source="efficiency" />
      <InputContainer source="aggregator_module_healthcheck_url" />
      <InputContainer source="network_ip" />
      <InputContainer source="mode" Field={SelectField} fieldCustomProps={{ choices: siteModeChoices }} />
      <InputContainer source="useful_energy_at_1mw" />
    </Box>
  );
};

export const SiteList = () => {
  const filters = useMemo(
    () => [
      <SearchInput source="search" key={"search"} alwaysOn autoFocus />,
      <ReferenceInput
        source="id__in"
        key="id__in"
        reference={baseResources.sites.GROUPS}
        filter={{ type: "rte_edr" }}
        label="EDR Selection"
        alwaysOn
      >
        <AutocompleteInput
          optionValue="site_ids"
          label="EDR Selection"
          parse={(selectedGroupIds) => {
            console.log("selectedGroupIds:", selectedGroupIds);
            if (!selectedGroupIds || !selectedGroupIds.length || !Array.isArray(selectedGroupIds)) return null;
            return selectedGroupIds;
          }}
          sx={{ minWidth: 300 }}
        />
      </ReferenceInput>,
      <ReferenceInput source="name__ilike" key="name__ilike" label="Site" reference={baseResources.sites.SITES}>
        <AutocompleteInput optionText="name" label="Site" />
      </ReferenceInput>,

      <TextInput source="city" key="city" label="City" />,
      <AutocompleteInput source="country" key="country" label="Country" choices={siteCountryChoices} />,
      <TextInput source="zipcode" key="zipcode" label="Zip Code" />,
      <NumberInput source="max_cycles" key="max_cycles" label="Max Cycles" />,
      <NumberInput source="max_cycles__lt" key="max_cycles__lt" label="Max Cycles (Less Than)" />,
      <NumberInput source="max_cycles__gt" key="max_cycles__gt" label="Max Cycles (Greater Than)" />,
      <TextInput
        source="aggregator_module_healthcheck_url"
        key="aggregator_module_healthcheck_url"
        label="Healthcheck URL"
      />,
      <TextInput source="network_ip" key="network_ip" label="Network IP" />,
      <AutocompleteInput source="dso" key="dso" label="DSO" choices={allDsoChoices} />,
      <TextInput source="rte_code" key="rte_code" label="RTE Code" />,
      <NumberInput source="rack_count" key="rack_count" label="Rack Count" />,
      <NumberInput source="min_power_mw" key="min_power_mw" label="Min Power (MW)" />,
      <NumberInput source="max_power_mw" key="max_power_mw" label="Max Power (MW)" />,
      <NumberInput source="efficiency" key="efficiency" label="Efficiency" />,
      <NumberInput source="latitude" key="latitude" label="Latitude" />,
      <NumberInput source="longitude" key="longitude" label="Longitude" />,
      <NumberInput source="max_soc_mwh" key="max_soc_mwh" label="Max SOC (MWh)" />,
      <NumberInput source="soh" key="soh" label="SOH" />,
      <NumberInput source="useful_energy_at_1mw" key="useful_energy_at_1mw" label="Useful Energy at 1MW" />,
      <NumberInput source="soc_inf" key="soc_inf" label="SOC Inf" />,
      <NumberInput source="soc_sup" key="soc_sup" label="SOC Sup" />,
      <NumberInput source="soc_reserve_inf" key="soc_reserve_inf" label="SOC Reserve Inf" />,
      <NumberInput source="soc_reserve_sup" key="soc_reserve_sup" label="SOC Reserve Sup" />,
      <TextInput source="prm" key="prm" label="PRM" />,
      <AutocompleteInput
        source="site_operator"
        key="site_operator"
        label="Site Operator"
        choices={siteOperatorChoices}
      />,
      <DateTimeInput source="created_at" key="created_at" label="Created At" />,
      <TextInput source="supplier" key="supplier" label="Supplier" />,
      <BooleanInput source="iecharge" key="iecharge" label="IE Charge" />,
      <NumberInput source="rte_fcr_computation_version" key="rte_fcr_computation_version" label="RTE FCR Version" />,
    ],
    [],
  );
  return (
    <List sort={{ field: "created_at", order: "DESC" } as SortPayload} filters={filters}>
      <Datagrid
        sort={{ field: "created_at", order: "DESC" } as SortPayload}
        rowClick="expand"
        expand={<SiteRowExpand />}
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: "lightGrey",
          },
        }}
      >
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS} sortable={false}>
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="country" />
        <TextField source="dso" />
        <BooleanField source="iecharge" label="IECharge" />
        <TextField source="rte_code" label={"Tranche TM site"} />
        <TextField source="site_operator" sortable={false} />
        <StatusField source="configuration_status" sortable={false} />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const StatusField = ({ source, sortable = false }: { source: string; sortable?: boolean }) => {
  const record = useRecordContext({ source, sortable });
  const theme = useTheme();

  const getTagInfos = useCallback(
    (currentSite: RaRecord) => {
      if (
        !currentSite?.desired_configuration ||
        !currentSite?.reported_configuration ||
        currentSite?.desired_configuration?.id !== currentSite?.reported_configuration?.id
      ) {
        return { color: theme.palette.warning.main, text: "Pending Synchronization" };
      }
      return { color: theme.palette.success.main, text: "Synchronized" };
    },
    [theme.palette.success.main, theme.palette.warning.main],
  );
  const tagInfos = getTagInfos(record);

  return record ? (
    <Stack direction={"row"} spacing={1}>
      <ColorTag color={tagInfos.color} />
      <Typography variant="body2">{tagInfos.text}</Typography>
    </Stack>
  ) : null;
};

export default StatusField;
