import { Tooltip, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { Instance } from "@popperjs/core";
import { format } from "date-fns";

interface MaintenanceCalendarTooltipProps {
  event: {
    title: string;
    operator: string;
    start: Date;
    end: Date;
    type: string;
  };
  children: any;
}

const MaintenanceCalendarTooltip = ({ event, children }: MaintenanceCalendarTooltipProps) => {
  console.log("event:", event);
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const formatDate = (date: Date) => {
    return format(date, "dd/MM/yyyy HH:mm");
  };

  return (
    <Tooltip
      title={
        <Box>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {event.title}
          </Typography>
          <Typography variant="body2">Start: {formatDate(event.start)}</Typography>
          <Typography variant="body2">End: {formatDate(event.end)}</Typography>
          <Typography variant="body2">Operator: {event.operator}</Typography>
          <Typography variant="body2">type: {event.type}</Typography>
        </Box>
      }
      arrow
      placement="top"
      slotProps={{
        popper: {
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              if (areaRef.current) {
                return new DOMRect(positionRef.current.x, areaRef.current.getBoundingClientRect().y, 0, 0);
              }
              return new DOMRect();
            },
          },
        },
      }}
    >
      <Box ref={areaRef} onMouseMove={handleMouseMove}>
        {children}
      </Box>
    </Tooltip>
  );
};

export default MaintenanceCalendarTooltip;
