import { MaskType } from "components/entities/tsoTests/testsAfrrRte/types";
import { baseResources } from "config_infos";
import { useCallback, useEffect, useState } from "react";
import { FieldProps, Identifier, SortPayload, useDataProvider, useRecordContext } from "react-admin";

const PlanningMaskField = (props: FieldProps) => {
  const record = useRecordContext(props);
  const dataprovider = useDataProvider();
  const [mask, setMask] = useState<MaskType | null>(null);

  const fetchMasks = useCallback(
    async (site_id: Identifier) => {
      const now = new Date();
      const { data } = await dataprovider.getList(baseResources.plannings.PLANNING_MASKS, {
        sort: { field: "id", order: "ASC" } as SortPayload,
        pagination: { page: 1, perPage: 1 },
        filter: {
          site_id,
          start_date__lt: now.toISOString(),
          end_date__gt: now.toISOString(),
        },
      });
      return data;
    },
    [dataprovider],
  );

  useEffect(() => {
    if (!record.site_ids.length) return;
    const firstSiteId = record.site_ids[0];

    fetchMasks(firstSiteId)
      .then((data: MaskType[]) => {
        setMask(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [fetchMasks, record.site_ids]);

  if (!record || !mask) return null;
  return record ? <span>{mask?.operating_mode}</span> : null;
};

export default PlanningMaskField;
