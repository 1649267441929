import { Box, Divider, Stack } from "@mui/material";
import CustomBooleanField from "components/commons/fields/CustomBooleanField";
import { baseResources } from "config_infos";
import { franceGroupTypeChoices } from "enums/reactAdminChoices";
import {
  Datagrid,
  DateField,
  Labeled,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import { useFetchSiteStatuses } from "../hooks/useFetchSiteStatuses";

export default function GroupBasicInfos() {
  return (
    <Box p={2}>
      <Stack direction={"row"} spacing={4}>
        <Labeled source="name">
          <TextField source="name" fullWidth />
        </Labeled>
        <Labeled source="code">
          <TextField source="code" fullWidth />
        </Labeled>
        <Labeled source="code_tm">
          <TextField source="code_tm" fullWidth />
        </Labeled>
        <Labeled source="mode">
          <TextField source="mode" fullWidth />
        </Labeled>
        <Labeled source={"type"}>
          <SelectField source={"type"} choices={franceGroupTypeChoices} />
        </Labeled>
      </Stack>
      <Divider sx={{ p: 2 }} />
      <Stack>
        <Labeled source={"Sites"} m={2}>
          <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} label="Sites">
            <StatusList />
          </ReferenceArrayField>
        </Labeled>
      </Stack>
    </Box>
  );
}

const StatusList = () => {
  const { site_ids } = useRecordContext();
  const { statuses, loading } = useFetchSiteStatuses(site_ids);

  return (
    <Datagrid data={statuses} total={statuses.length} isLoading={loading} bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="site_id" reference={baseResources.sites.SITES} link="show" />
      <DateField source="timestamp" locales={"fr-FR"} showTime />
      <CustomBooleanField source="network" />
      <CustomBooleanField source="control" />
      <CustomBooleanField source="measure" />
      <CustomBooleanField source="sequence" />
      <CustomBooleanField source="power_clamping" />
      <CustomBooleanField source="aggregator_module" />
    </Datagrid>
  );
};
