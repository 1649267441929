import { Box } from "@mui/material";
import { generateJwtToken } from "auth/jwtUtils";
import { subHours } from "date-fns";
import { SiteContryEnum } from "enums/globalEnums";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Identifier, Loading, useRecordContext } from "react-admin";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Link } from "react-router-dom";

enum ViewTypeEnum {
  softval = "d-solo",
  grafana = "d",
}

type SiteTelemetryProps = {
  siteId: Identifier;
  country: SiteContryEnum;
};

const SiteTelemetry = () => {
  const record = useRecordContext();
  return <SiteTelemetryGrafanaChart siteId={record.id} country={record.country} />;
};

export const SiteTelemetryGrafanaChart: React.FC<SiteTelemetryProps> = ({ siteId, country }) => {
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    async function fetchJwtToken() {
      const privateKey = process.env.REACT_APP_GRAFANA_API_KEY as string;
      const token = await generateJwtToken(privateKey);
      if (token) {
        setJwtToken(token);
      }
    }

    fetchJwtToken();
  }, []);

  const getCountrySlug = useCallback((country: SiteContryEnum) => {
    switch (country) {
      case SiteContryEnum.Finland:
        return "de404w8ezefb4c/operations-finlande";
      case SiteContryEnum.France:
        return "ce1mnuvm20gzka/operations-france";
      default: // defaults to France
        return "ce1mnuvm20gzka/operations-france";
    }
  }, []);

  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1); // Increment refreshKey to refresh the iframe
  };

  const BASE_GRAFANA_URL = "https://joulestream.data.nwstorm.io";
  const countrySlug = getCountrySlug(country);
  const now = new Date();
  const to = now.getTime();
  const from = subHours(now, 1).getTime();

  const getSrc = useCallback(
    (viewType: ViewTypeEnum) => {
      const authTokenParam = viewType === ViewTypeEnum.softval ? `&auth_token=${jwtToken}` : "";
      return `${BASE_GRAFANA_URL}/${viewType}/${countrySlug}?orgId=1&from=${from}&to=${to}&panelId=2&var-site_id=${siteId}${authTokenParam}`;
    },
    [jwtToken, BASE_GRAFANA_URL, countrySlug, from, to, siteId],
  );

  if (!jwtToken) {
    return <Loading />;
  }

  const softvalSrc = getSrc(ViewTypeEnum.softval);
  const grafanaSrc = getSrc(ViewTypeEnum.grafana);
  return (
    <Box>
      <Button
        onClick={handleRefresh}
        label="Refresh chart"
        variant="contained"
        sx={{ mb: 2 }}
        startIcon={<RefreshIcon />}
      />
      <Button
        label="Go to Grafana"
        variant="contained"
        sx={{ mb: 2, ml: 2 }}
        startIcon={<ShowChartIcon />}
        component={Link}
        to={grafanaSrc}
        target="_blank"
      />

      <Box minHeight={500} height={500}>
        <iframe
          key={refreshKey} // Forces iframe to reload when refreshKey changes
          src={softvalSrc}
          width="100%"
          height="100%"
          title="Grafana Dashboard"
        ></iframe>
      </Box>
    </Box>
  );
};

export default SiteTelemetry;
