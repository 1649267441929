import { SignJWT, importPKCS8 } from "jose";

// Function to generate JWT token
export const generateJwtToken = async (privateKey: string): Promise<string | null> => {
  if (!privateKey) {
    console.error("Private key not found");
    return null;
  }

  try {
    // Replace \n with actual newlines
    const formattedKey = privateKey.replace(/\\n/g, "\n");

    // Import the private key using jose (PKCS#8 formatted key)
    const key = await importPKCS8(formattedKey, "ES256");

    // Define the payload with the claims
    const jwt = await new SignJWT({ sub: "user123" }) // TODO: Replace with actual user ID
      .setProtectedHeader({ alg: "ES256" }) // Use ES256 for ECDSA P-256 key
      .setIssuedAt()
      .setExpirationTime("100000h")
      .sign(key);

    return jwt;
  } catch (error) {
    console.error("Error generating JWT", error);
    return null;
  }
};
