import {
  // AccountInfo,
  Configuration,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";

const {
  REACT_APP_AUTHENTICATION_SCOPE,
  REACT_APP_MSAL_CLIENT_ID,
  REACT_APP_MSAL_AUTHORITY,
  REACT_APP_API_BASE_URL,
  // REACT_APP_AZURE_ADMIN_GROUP_ID,
} = process.env as { [key: string]: string };
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: REACT_APP_MSAL_CLIENT_ID,
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority: `https://login.microsoftonline.com/${REACT_APP_MSAL_AUTHORITY}`,
    // Full redirect URL, in form of http://localhost:8080/auth-callback
    redirectUri: `${REACT_APP_API_BASE_URL}/auth-callback`,
    // We need to disable this feature because it is already handled by react-admin, and would otherwise conflict
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// TODO: uncomment when admin group is updated on Azure
// const getPermisions = (roles: string[]) => {
//   const permissions = ["user"];
//   if (roles.includes(REACT_APP_AZURE_ADMIN_GROUP_ID)) {
//     permissions.push("admin");
//   }
//   return permissions;
// };

/**
 * Custom function to map roles to permissions, using the rolesPermissionMap above.
 * Alternatively, you can use the MS Graph API to get more information about the user's roles and groups.
 */
export const getPermissionsFromAccount = async () =>
  // account: AccountInfo
  {
    // TODO: uncomment when admin group is updated on Azure
    // const roles = account?.idTokenClaims?.roles ?? [];
    // return getPermisions(roles);
    return ["user", "admin"];
  };

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: [REACT_APP_AUTHENTICATION_SCOPE],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest: SilentRequest = {
  scopes: [REACT_APP_AUTHENTICATION_SCOPE],
  // forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const MSALObj = new PublicClientApplication(msalConfig);

export const msalAccountName = MSALObj.getActiveAccount()?.name || "Unauthenticated user";
export const msalTenantId = MSALObj.getActiveAccount()?.tenantId || "0";
