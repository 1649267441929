import { useCallback, useEffect, useState } from "react";

import { baseResources } from "config_infos";
import usePlanningsHelpers from "./usePlanningsHelpers";
import { InitialDatasets, MaskType, PlanningType } from "types";

export function usePlanningsData(siteId: number) {
  const {
    dateRange,
    setDateRange,
    consolidatePlannings,
    fetchAllRessourcesWithinTimeRange,
    error,
    loading,
    setLoading,
  } = usePlanningsHelpers();

  const [datasets, setDatasets] = useState<InitialDatasets>({
    plannings: [],
    masks: [],
  });

  const fetchResources = useCallback(async () => {
    const [plannings, masks] = await Promise.all([
      fetchAllRessourcesWithinTimeRange(baseResources.plannings.PLANNINGS, siteId),
      fetchAllRessourcesWithinTimeRange(baseResources.plannings.PLANNING_MASKS, siteId),
    ]);

    setDatasets({
      plannings: consolidatePlannings(plannings as PlanningType[]),
      masks: masks as MaskType[],
    });

    setLoading(false);
  }, [siteId, fetchAllRessourcesWithinTimeRange, consolidatePlannings, setLoading]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const data = {
    ...datasets,
    dateRange,
  };

  return { data, error, loading, dateRange, setDateRange };
}
