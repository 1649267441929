import {
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  RaRecord,
  ReferenceArrayField,
  SelectField,
  Show,
  ShowActions,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { useParams } from "react-router-dom";

import { Avatar, Box, Card, CardActions, CardContent, CardHeader, Divider } from "@mui/material";

import { baseResources } from "config_infos";

import SiteTelemetry from "components/charts/grafana/SiteTelemetry";
import PlanningChart from "components/charts/planning/PlanningChart";
import { MaintenancesList } from "components/entities/maintenances/MaintenancesList";
import { StatusesList } from "components/entities/site_service/sites_statuses/StatusesList";
import { SiteContryEnum } from "enums/globalEnums";
import { siteModeChoices } from "enums/reactAdminChoices";
import "react-big-scheduler-stch/lib/css/style.css";
import SiteConfigurations from "./SiteConfigurations";
import SiteLocation from "./SiteLocation";

type LabeledFieldProps = {
  source: string;
  label?: string;
  record?: RaRecord;
  ComponentField?: React.ComponentType<any>;
};

const LabeledField = ({ source, label, ComponentField = TextField }: LabeledFieldProps) => (
  <Labeled source={source} label={label || source}>
    <ComponentField source={source} fullWidth />
  </Labeled>
);

type SectionCardContainerProps = {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

const SectionCardContainer = ({ children, title, subtitle, actions }: SectionCardContainerProps) => {
  const titleFirstLetter = title ? title?.charAt(0).toUpperCase() : null;
  return (
    <Card elevation={4}>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: "h6" }}
        subheader={subtitle}
        avatar={
          titleFirstLetter ? (
            <Avatar aria-label="title" sx={{ bgcolor: "primary.main" }}>
              {titleFirstLetter}
            </Avatar>
          ) : (
            <></>
          )
        }
      />
      <Divider />

      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  );
};

export const SiteShow = () => {
  const { id } = useParams();

  return (
    <Show actions={<ShowActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General Infos">
          <SectionCardContainer title="Site Infos">
            <Box display={"grid"} gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr", md: "1fr 1fr 1fr" }} gap={2}>
              <LabeledField source="name" />
              <LabeledField source="dso" />
              <TrancheTmSiteField source="rte_code" />
              <LabeledField source="city" />
              <LabeledField source="zipcode" />
              <LabeledField source="country" />
              <Labeled source="mode">
                <SelectField source={"mode"} choices={siteModeChoices} />
              </Labeled>
              <LabeledField source="soc_reserve_inf" ComponentField={NumberField} />
              <LabeledField source="soc_inf" ComponentField={NumberField} />
              <LabeledField source="soc_sup" ComponentField={NumberField} />
              <LabeledField source="soc_reserve_up" ComponentField={NumberField} />
              <LabeledField source="useful_energy_at_1mw" ComponentField={NumberField} />
            </Box>
          </SectionCardContainer>
          <SectionCardContainer title="Group Infos">
            <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS}>
              <Datagrid bulkActionButtons={false}>
                <TextField source="name" />
                <TextField source="code" />
                <DateField source="created_at" />
              </Datagrid>
            </ReferenceArrayField>
          </SectionCardContainer>
          <SectionCardContainer title="Telemetry Chart" subtitle="Telemetry over the last hour">
            <SiteTelemetry />
          </SectionCardContainer>
          <SectionCardContainer title="Planning Charts" subtitle={"Plannings and masks in range"}>
            <PlanningChart siteId={id} />
          </SectionCardContainer>
          <SectionCardContainer title="Maintenances" subtitle="Current Site maintenances">
            <MaintenancesList
              resource={baseResources.maintenance.MAINTENANCE}
              filter={{ site_id: id, end__gt: new Date().toISOString() }}
            />
          </SectionCardContainer>
          <SectionCardContainer title="Statuses" subtitle="All sites statuses">
            <StatusesList
              resource={baseResources.sites.STATUSES}
              filter={{ site_id: id }}
              isSite={true}
              disableSyncWithLocation
            />
          </SectionCardContainer>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Configurations" path="configurations">
          <SiteConfigurations />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Map" path="siteMap">
          <SiteLocation />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const TrancheTmSiteField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  if (record.country !== SiteContryEnum.France) return <></>;
  return <LabeledField source={source} record={record} label={"Tranche TM site"} />;
};
