import { Chart, ChartData, ChartOptions, ChartTypeRegistry, LegendItem } from "chart.js";
import { format } from "date-fns";
import "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";
import { DateRangeType, MaskType, PlanningType } from "types";
import { PLANNING_MASK_MODE_ENUM, PLANNING_MODE_ENUM } from "enums";
import { useMemo } from "react";
import { generateChartDatasets, getColorByMode, maskModeColors, planningModeColors } from "../../helpers";
import { PlanningModeEnum } from "enums/globalEnums";

export function useChartConfig(plannings: PlanningType[], masks: MaskType[], dateRange: DateRangeType) {
  const datasets = useMemo(() => {
    const planningDatasets = generateChartDatasets(
      plannings,
      PLANNING_MODE_ENUM,
      getColorByMode(planningModeColors),
      "mode",
      "Plannings",
    );
    const maskDatasets = generateChartDatasets(
      masks,
      PLANNING_MASK_MODE_ENUM,
      getColorByMode(maskModeColors),
      "operating_mode",
      "Masks",
    );
    return [...maskDatasets, ...planningDatasets];
  }, [plannings, masks]);

  const options = useMemo(() => getChartOptions(dateRange), [dateRange]);

  const chartConfig = useMemo(() => {
    const labels = ["Masks", "Plannings"];
    const data = {
      labels,
      datasets,
    };
    return { data, options };
  }, [datasets, options]);

  return chartConfig;
}

const getModeValue = (mode: PlanningModeEnum, planning: PlanningType) => {
  switch (mode) {
    case PlanningModeEnum.fcr_rte_v2:
      return planning.fcr_engagement;
    case PlanningModeEnum.fcr_rte_v2_no_reserve:
      return planning.fcr_engagement;
    case PlanningModeEnum.afrr_rte:
      return planning.afrr_engagement;
    case PlanningModeEnum.afrr_fcr_rte:
      return "TODO";
    case PlanningModeEnum.fingrid_fcrn:
      return planning.fcrn_engagement;
    default:
      return "";
  }
};

function getChartOptions(dateRange: DateRangeType): ChartOptions<"bar"> {
  return {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "dd/MM/yyyy HH:mm",
          },
          tooltipFormat: "dd/MM/yyyy HH:mm",
        },
        min: dateRange.startDate.toISOString(),
        max: dateRange.endDate.toISOString(),
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        title: {
          text: "Plannings and Masks",
          display: false,
        },
        fullSize: false,
        maxWidth: 100,
        labels: {
          generateLabels(chart: Chart) {
            const datasets: any = chart.data.datasets;
            const invalidDataset = (dataset: any) => {
              return !dataset?.data || !dataset?.data?.length;
            };
            const getStrokeStyle = (dataset: any) => {
              if (invalidDataset(dataset)) return undefined;
              const infos = dataset?.data[0];
              return infos?.overridable ? "black" : "red";
            };
            return datasets.map((dataset: any, index: number) => {
              return {
                text: dataset.label || "",
                fillStyle: dataset.backgroundColor as any, // Fill color for the rectangle
                strokeStyle: getStrokeStyle(dataset), // border color
                hidden: !chart.isDatasetVisible(index),
                lineWidth: 0, // border width
                datasetIndex: index,
              };
            });
          },
          filter: function (legendItem: LegendItem, chartData: ChartData<keyof ChartTypeRegistry>) {
            const targetedDataset = chartData.datasets.find((dataset: any) => dataset.label === legendItem.text);
            const is_present_in_dataset = targetedDataset && targetedDataset.data?.length > 0;
            return !!is_present_in_dataset;
          },
        },
      },
      tooltip: {
        position: "average",
        callbacks: {
          title: function (context: any) {
            if (context.length === 0) return "Planning";
            const dataIndex = context[0].dataIndex;
            const data = context[0]?.dataset?.data[dataIndex];
            const isMaintenance = data.y === "Plannings" && !data.overridable;
            const label = context[0]?.dataset?.label || "";
            return isMaintenance ? `${label} (Maintenance)` : label;
          },
          label: function ({ raw }: any) {
            return raw.x.map((date: Date) => format(date, "dd/MM/yyyy HH:mm")).join(" - ");
          },
          footer(tooltipItem) {
            const mode = tooltipItem[0].dataset.label;
            const data = tooltipItem[0].raw as PlanningType;
            const value = getModeValue(mode as PlanningModeEnum, data);
            return value ? `Engagement value: ${value} MW` : "";
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            xMin: new Date().toISOString(),
            xMax: new Date().toISOString(),
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      },
    },
  };
}
