import { Create, DateInput, NumberInput, SimpleForm, TextInput } from "react-admin";

const TopNiveauGatesCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <DateInput source="target_gate" />
        <DateInput source="application_gate" />
        <TextInput source="deposit_date" />
        <TextInput source="xml_file_link" />
        <NumberInput source="revision_number" />
        <TextInput source="status" />
        <TextInput source="ticket_number" />
      </SimpleForm>
    </Create>
  );
};

export default TopNiveauGatesCreate;
