import axios from "axios";
import { baseResources } from "config_infos";
import { useCallback, useEffect, useState } from "react";

export const useFetchSiteStatuses = (site_ids: number[]) => {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStatuses = useCallback(async (site_id: number) => {
    setLoading(true);
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.STATUSES}`,
      params: {
        page: 1,
        size: 1,
        site_id,
        order_by: "-timestamp",
      },
    });
    return data.items;
  }, []);

  useEffect(() => {
    if (site_ids.length === 0) {
      setStatuses([]);
      setLoading(false);
      return;
    }
    const calls = site_ids.map((site_id: number) => fetchStatuses(site_id));
    Promise.allSettled(calls)
      .then((results) => {
        let fetchedStatuses: any = [];
        results.forEach((result) => {
          if (result.status === "rejected") {
            throw new Error(`Failed to fetch data: ${result.reason}`);
          }
          if (result.value) {
            fetchedStatuses = [...fetchedStatuses, ...result.value];
          }
        });
        setStatuses(fetchedStatuses);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  }, [site_ids, fetchStatuses]);
  return {
    statuses,
    loading,
  };
};
