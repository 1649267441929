import { Avatar } from "@mui/material";
import Icon from "assets/images/chargingStation.png";

type IEChargeIconProps = {
  style?: React.CSSProperties;
  avatarColor?: string;
};

const IEChargeIcon = ({ style, avatarColor }: IEChargeIconProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: (theme) => avatarColor || theme.palette.primary.main,
      }}
    >
      <img src={Icon} alt="Icon" style={{ ...style, transform: "translate(2px, 0px)" }} />
    </Avatar>
  );
};

export default IEChargeIcon;
