import { baseResources } from "config_infos";
import { FormattedGroupTypeEnum, GroupTypeEnum } from "enums/globalEnums";
import { groupModeChoices } from "enums/reactAdminChoices";
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FunctionField,
  Identifier,
  List,
  RaRecord,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";
import GroupFilterSidebar from "./GroupFilterSidebar";
import PlanningMaskField from "./PlanningMaskField";
import SiteNumberTextField from "./SiteNumberTextField";
import SiteTable from "./SiteTable";

export const GroupList = () => {
  const groupFilters = [
    <ReferenceInput source="id__in" reference={baseResources.sites.GROUPS} key="id__in" label="name" alwaysOn autoFocus>
      <AutocompleteInput
        optionText={({ id, name, code }: { id: Identifier; name: string; code: string }) => {
          return `${id} - ${name} - ${code}`;
        }}
        label="name"
        autoFocus
      />
    </ReferenceInput>,
    <TextInput label="Code" source="code" key="code" />,
    <TextInput label="Code DSO" source="code_dso" key="code_dso" />,
    <SelectInput label="Mode" source="mode" key="mode" choices={groupModeChoices} />,
  ];

  return (
    <List
      filters={groupFilters}
      filterDefaultValues={{ type: GroupTypeEnum.rte_edr }}
      aside={<GroupFilterSidebar />}
      sx={{
        flexDirection: "column",
      }}
    >
      <TypeDependantDatagrid />
    </List>
  );
};

const TypeDependantDatagrid = () => {
  const { filterValues } = useListContext();
  const groupType = filterValues?.type;
  const codeLabel =
    `code ${FormattedGroupTypeEnum[filterValues.type as keyof typeof FormattedGroupTypeEnum]}` || "code";

  const getColumns = () => {
    const commonColumns = [
      <TextField source="name" key="name" />,
      <TextField source="code" sortable={false} key="code" label={codeLabel} />,
      <FunctionField
        key="site_number"
        label="Number of sites"
        render={(record: RaRecord) => <SiteNumberTextField record={record} />}
        sortable={false} // Disable sorting for this field
      />,
    ];

    const buttonColumns = [<ShowButton key="show" />, <EditButton key="edit" />];

    switch (groupType) {
      case GroupTypeEnum.rte_edr:
      case GroupTypeEnum.rte_eda:
      case GroupTypeEnum.fingrid_pe:
      case GroupTypeEnum.fingrid_ro:
        return [
          ...commonColumns,
          <TextField source="mode" sortable={false} key="mode" />,
          <PlanningMaskField source="current_planning_mask" key="planning_mask" />,
          ...buttonColumns,
        ];
      case GroupTypeEnum.rte_edc:
        return [...commonColumns, ...buttonColumns];
      case GroupTypeEnum.rte_edp:
        return [
          ...commonColumns,
          <TextField source="mode" sortable={false} key="mode" />,
          <PlanningMaskField source="current_planning_mask" key="planning_mask" />,
          ...buttonColumns,
        ];
      default:
        return null;
    }
  };

  const columns = getColumns();

  if (!columns) return null;

  return (
    <Datagrid rowClick="expand" bulkActionButtons={false} expand={<SiteTable />} expandSingle>
      {columns}
    </Datagrid>
  );
};
