import { directionChoices } from "enums/reactAdminChoices";
import { Create, NumberInput, SelectField, SimpleForm, TextInput } from "react-admin";

const TopNiveauBidsCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <NumberInput source="max_quantity" />
        <NumberInput source="min_quantity" />
        <NumberInput source="price" />
        {/* TODO: add ReferenceField once Backend updated */}
        {/* <ReferenceInput source="edr_id" reference={baseResources.sites.GROUPS} /> */}
        <TextInput source="edr_id" />
        <SelectField source="direction" choices={directionChoices} />
        <NumberInput source="fat" />
        {/* TODO: add ReferenceField once Backend updated */}
        {/* <ReferenceInput source="gate_id" reference={baseResources.topNiveau.GATES} /> */}
        <TextInput source="gate_id" />
      </SimpleForm>
    </Create>
  );
};

export default TopNiveauBidsCreate;
