import GroupsIcon from "@mui/icons-material/Groups";
import { Card, CardContent } from "@mui/material";
import TopFilterList from "components/commons/filters/TopFilterList";
import { GroupTypeEnum } from "enums/globalEnums";
import { FilterListItem } from "react-admin";

const GroupFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, minWidth: 180 }}>
    <CardContent>
      <TopFilterList label="Groupe type" icon={<GroupsIcon />}>
        <FilterListItem label="EDR" value={{ type: GroupTypeEnum.rte_edr }} />
        <FilterListItem label="EDP" value={{ type: GroupTypeEnum.rte_edp }} />
        <FilterListItem label="EDC" value={{ type: GroupTypeEnum.rte_edc }} />
        <FilterListItem label="EDA" value={{ type: GroupTypeEnum.rte_eda }} />
        <FilterListItem label="PE" value={{ type: GroupTypeEnum.fingrid_pe }} />
        <FilterListItem label="RO" value={{ type: GroupTypeEnum.fingrid_ro }} />
      </TopFilterList>
    </CardContent>
  </Card>
);

export default GroupFilterSidebar;
