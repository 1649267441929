import { styled } from "@mui/material";
import { FilterList } from "react-admin";

const TopFilterList = styled(FilterList)(({ theme }) => ({
  ".MuiList-root": {
    display: "flex",
    gap: theme.spacing(1),
  },
  "& .MuiListItem-root": {
    // Targeting FilterListItem components
    padding: theme.spacing(1),
    borderRadius: "4px",
    border: "1px solid gainsboro",
    "& .MuiTypography-root": {
      fontWeight: "bold",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default TopFilterList;
