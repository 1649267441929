import { DateField, NumberField, Show, TextField } from "react-admin";

const TopNiveauGatesShow = () => (
  <Show>
    <TextField source="id" />
    <DateField source="created_at" locales={"fr-FR"} showTime />
    <DateField source="updated_at" locales={"fr-FR"} showTime />
    <DateField source="target_gate" locales={"fr-FR"} showTime />
    <DateField source="application_gate" locales={"fr-FR"} showTime />
    <DateField source="deposit_date" locales={"fr-FR"} showTime />
    <TextField source="xml_file_link" />
    <NumberField source="revision_number" />
    <TextField source="status" />
    <TextField source="ticket_number" />
  </Show>
);

export default TopNiveauGatesShow;
