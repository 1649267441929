import { DateField, NumberField, Show, TextField } from "react-admin";

const TopNiveauBidsShow = () => {
  return (
    <Show>
      <TextField source="id" />
      <DateField source="created_at" locales={"fr-FR"} showTime />
      <DateField source="updated_at" locales={"fr-FR"} showTime />
      <NumberField source="max_quantity" />
      <NumberField source="min_quantity" />
      <NumberField source="price" />
      {/* TODO: add ReferenceField once Backend updated */}
      <TextField source="edr_id" label={"EDR code"} />
      <TextField source="direction" />
      <NumberField source="fat" />
      <TextField source="gate_id" />
      {/* TODO: add ReferenceField once Backend updated */}
      {/* <ReferenceField source="gate_id" reference={baseResources.topNiveau.GATES} /> */}
    </Show>
  );
};

export default TopNiveauBidsShow;
