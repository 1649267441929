import { baseResources } from "config_infos";
import { useMemo } from "react";
import {
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  useResourceContext,
} from "react-admin";
import { optimizerGroupTypeChoices } from "../opti_enums";

export default function GroupsCertificationsList(props: any) {
  const resource = useResourceContext();

  const GroupsCertificationsFilters = useMemo(
    () => [<SelectInput key="group_type" label="Group Type" source="group_type" choices={optimizerGroupTypeChoices} />],
    [],
  );

  const listProps = {
    ...props,
    GroupsCertificationsFilters,
  };

  if (resource === baseResources.optimizer.GROUPS_CERTIFICATIONS)
    [
      listProps.GroupsCertificationsFilters.push(
        <ReferenceInput
          key={"certification_id"}
          label="Certification Name"
          source="certif_name"
          reference={baseResources.optimizer.CERTIFICATIONS}
        />,
      ),
    ];

  return (
    <List filters={listProps.GroupsCertificationsFilters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="group_type" />
        <TextField source="group_code" />
        <TextField source="status" />
        <ReferenceField
          label="Certification Name"
          source="certification_id"
          sortable={false}
          reference={baseResources.optimizer.CERTIFICATIONS}
          link={"show"}
        />
        <ReferenceField label="Market" source="certification_id" reference="certifications" link="show">
          <TextField source="market" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  );
}
